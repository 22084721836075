.card {
    @apply bg-gray-700 !important;
}

.card-body {
    @apply bg-gray-700 !important;

}

.pricing-header {
    width: 100%;  
    text-align: center;
    margin-top: 20px;
}


a.nav-link.disabled {
    color: rgb(124, 124, 124)!important;
}